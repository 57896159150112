.tag_container {
  box-sizing: border-box;
  padding: 25px;
  display: flex;
  align-items: center;
  gap: 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
  color: #000;
}
.tag_container img {
  width: 40px;
}
.tag_content {
  letter-spacing: 0.6px;
}
@media (max-width: 1300px) {
  .tag_container {
    font-size: 1rem;
  }
}
@media (max-width: 650px) {
  .tag_container img {
    width: 30px;
    font-size: 1rem;
  }
}
