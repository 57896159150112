.button {
    padding: 10px;
    font-family: "Poppins", sans-serif;
    font-size: 1.2rem;
    width: fit-content;
    border: 3px solid #5637FA;
    cursor: pointer;
    /* border-radius: 15px; */
}
.content {
    position: relative;
    overflow: hidden;
    padding: 0 50px;
}
.content:before {
    content: "";
    position: absolute;
    width: 0;
    height: 3px;
    bottom: 0;
    left: 0;
    right: 100%;
    background-color: #5637FA;
    border-radius: 100px;
    visibility: hidden;
    transition: all 0.4s ease-in-out;
}
.content:hover:before {
    visibility: visible;
    width: 50%;
    left: 100%;
}
.content_primary {
    position: relative;
    overflow: hidden;
    padding: 0 50px;
    text-decoration: none;
    color: #fff;
}
.content_primary:before {
    content: "";
    position: absolute;
    width: 0;
    height: 3px;
    bottom: 0;
    left: 0;
    right: 100%;
    background-color: #fff;
    border-radius: 100px;
    visibility: hidden;
    transition: all 0.4s ease-in-out;
}
.content_primary:hover:before {
    visibility: visible;
    width: 50%;
    left: 100%;
}
@media (max-width: 650px) {
    .button {
        font-size: 1rem;
    }
    .content, .content_primary {
        padding: 0 20px;
    }
}