.container {
  position: relative;
  padding: 0;
  height: fit-content;
  box-sizing: border-box;
}
.homescreen {
  height: 100vh;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  background-image: linear-gradient(
    to bottom,
    #3b88ff 0,
    #aeb8fe 54%,
    #dde1fd 79.9%,
    #fff 100%
  );
}
.clouds {
  position: absolute;
  right: 0;
  height: 20vh;
  width: 9000px;
  background-image: url(../../assets/images/home_clouds.svg);
  background-repeat: repeat-x;
  background-size: contain;
  margin-top: 5vh;
  animation: slide 150s linear infinite;
}
@keyframes slide {
  0% {
    transform: translate(0);
  }
  100% {
    transform: translate(4413px); /* The image width */
  }
}
.skyimage {
  position: absolute;
  z-index: -100;
  width: 100%;
  /* object-fit: cover; */
}
.illustration_flex {
  display: flex;
  flex-direction: column;
  height: 100%;
  bottom: 0;
  justify-content: flex-end;
}
/* .lakeimage {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 88;
} */
.landimage {
  /* object-fit: cover; */
  position: absolute;
  /* top: 0; */
  z-index: 100;
  left: 0;
  width: 100%;
  /* height: 100%; */
  bottom: 0;
}
.hillimage {
  /* object-fit: contain; */
  /* position: absolute; */
  left: 0;
  width: 100%;
  /* height: 100%; */
}
.landimage,
.hillimage {
  z-index: 100;
}
.texture {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 102;
  mix-blend-mode: multiply;
  opacity: 0.15;
}
.grain {
  z-index: 102;
  mix-blend-mode: multiply;
  opacity: 0.4;
}
.homescreen img {
  /* position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; */
  /* object-fit: cover; */
}
.introtext {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%) !important;
  text-align: center;
  margin: 17vh 0;
  font-family: "Poppins", sans-serif;
  font-size: 4rem;
  color: #fff;
  font-weight: 600;
}

.scroll_container {
  width: 11px;
  height: 25px;
  border: 3px solid #fff;
  border-radius: 15px;
  position: absolute;
  bottom: 5vh;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  animation: breathe 5s ease-out infinite;
  cursor: pointer;
  z-index: 1000;
}

.scroller {
  width: 5px;
  border-radius: 8px;
  background-color: #fff;
  position: absolute;
  top: 2px;
  left: 3px;
  bottom: 20px;
  animation: scroller 2s ease-out infinite;
}

@keyframes breathe {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes scroller {
  0% {
    bottom: 20px;
  }
  5% {
    top: 4px;
  }
  32% {
    bottom: 4px;
  }
  66% {
    top: 20px;
    bottom: 4px;
  }
  100% {
    top: 4px;
    bottom: 20px;
  }
}
/* .clouds {
    height: 20vh;
    background-image: url(../../assets/images/home_clouds.svg);
}
.hills {
    position: absolute;
    width: 100%;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    z-index: 1;
    height: 50vh;
    background-image: url(../../assets/images/home_hills.svg);
} */
.nav_space {
  height: 5vh;
}
.about_container {
  position: relative;
  height: 100vh;
  background-image: linear-gradient(
    to bottom,
    #3b88ff 0,
    #aeb8fe 54%,
    #dde1fd 79.9%,
    #fff 100%
  );
  background-position: center;
  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
}
/* .about_content::after {
    content: "";
    height: 17vh;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%);
    z-index: 101;
} */
.about_content {
  position: relative;
  display: flex;
  padding: 9vh 20vw 0 20vw;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 100px;
  box-sizing: border-box;
}
.about_content img {
  height: 70vh;
}
.right_content {
  overflow: hidden;
  font-family: "Poppins", sans-serif;
  width: 60%;
  color: #fff;
  font-size: 1.1rem;
  line-height: 2rem;
}
.description {
  margin-bottom: 2vh;
  font-weight: 500;
}
.identity {
  display: flex;
  margin-bottom: 1vh;
}
.identity_left {
  width: 150px;
  font-weight: 600;
}
.checkout {
  color: #25004d;
  font-weight: 800;
  font-size: 1.6rem;
  margin: 5vh 0 2vh 0;
}
.button_flex {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.skills_container {
  min-height: 100vh;
  height: max-content;
  background-color: #f2f3ff;
  padding: 5vh 10vw 0 10vw;
  box-sizing: border-box;
}
.skills_flex {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.skills_inner_container {
  min-height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1000;
}
.projects_heading,
.skills_heading {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  text-align: center;
  color: #25004d;
  margin-bottom: 5vh;
  margin-top: 2.5vh;
}
.tags_container {
  display: flex;
  gap: 40px 20px;
  flex-wrap: wrap;
  padding-bottom: 5vh;
  justify-content: space-between;
}
.works_container {
  padding-top: 12vh;
  height: 100vh;
  box-sizing: border-box;
  background-color: #f2f3ff;
}
.contact_container {
  background-color: #f2f3ff;
  min-height: 80vh;
  height: max-content;
}
.contact_flex {
  padding: 10vh 0;
  gap: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.secondary_heading {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 1.3rem;
  text-align: center;
  color: #25004d;
  margin: 3vh 0 2.5vh 0;
  text-align: center;
}

.socials {
  width: 30%;
}
.social_flex {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}
.footer {
  height: 20vh;
  background-color: #08080a;
  text-align: center;
  padding: 5vh;
  box-sizing: border-box;
}
.copyright {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: lighter;
  color: #fff;
  margin-top: 2.5vh;
}
.map_container {
  width: 50%;
  overflow: none;
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  overflow: hidden;
}
.map {
  width: 60%;
  border: 10px solid #fff;
  border-radius: 10px;
}
.slideClass {
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  cursor: grab;
}
.slideImage {
  border-radius: 20px;
  width: 600px;
}
.slidecontainer {
  display: flex;
  justify-content: space-between;
  margin: 30px 0 10px 0;
  font-family: "Poppins", sans-serif;
}
.heading {
  display: flex;
  align-items: center;
}
.button {
  background-color: #5637fa;
  font-size: 0.8rem;
  color: #fff;
  padding: 15px 30px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.button:hover {
  background-color: #4825f8;
}
.button:active {
  outline: 1px solid #000;
}

@media (max-width: 1400px) {
  .slideImage {
    width: 450px;
  }
  .skills_heading,
  .projects_heading {
    font-size: 1.5rem;
  }
  .description {
    font-size: 1rem;
  }
  .socials {
    width: 50%;
  }
  .map_container {
    width: 40%;
  }
}
@media (max-width: 1100px) {
  .slideImage {
    width: 400px;
  }
}
@media (max-width: 1200px) {
  .about_content {
    padding: 0 15vw;
  }
  .right_content {
    width: 70%;
  }
  .contact_flex {
    flex-direction: column;
  }
  .socials,
  .map_container {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .illustration_flex {
    display: flex;
    transform: translateX(-25%);
    width: 200%;
  }
  .landimage {
    width: 200%;
    left: -50%;
  }
  .hillimage {
    /* position: absolute;
     */
    /* top: 0; */
    object-fit: cover;
  }
  .about_content {
    margin-top: 10vh;
  }
  .about_content::after {
    content: none;
  }
  .about_content img {
    display: none;
  }
  .right_content {
    width: 100%;
  }
  .tags_container {
    justify-content: space-around;
  }
  .contact_flex {
    flex-direction: column;
    gap: 50px;
  }
  .skills_container {
    padding: 0;
    padding-top: 5vh;
  }
  .socials {
    margin: 0 auto;
    width: 60%;
  }
  .map {
    text-align: center;
    margin-bottom: 50px;
    width: 80vw;
    margin: 0 20px;
  }
  .slideImage {
    width: 350px;
  }
  .nav_space {
    height: 5vh;
  }
}
@media (max-width: 650px) {
  .illustration_flex {
    display: flex;
    transform: translateX(-33%);
    width: 300%;
  }
  .landimage {
    width: 300%;
    left: -100%;
  }
  .about_container {
    min-height: 100vh;
    height: max-content;
  }
  .about_content {
    padding: 0 10vw;
    margin: 0;
  }
  .nav_space {
    height: 15vh;
  }
  .description {
    font-size: 1rem;
  }
  .checkout {
    font-size: 1.2rem;
  }
  .projects_heading {
    margin-top: 22vh;
    font-size: 1.5rem;
  }
  .skills_flex {
    justify-content: flex-start;
  }
  .skills_heading {
    margin-top: 10vh;
    font-size: 1.5rem;
  }
  .socials {
    width: 90%;
  }
  .slidecontainer {
    margin: none;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
  .slideClass {
    padding: 10px;
    display: block !important;
    width: 95% !important;
  }
  .works_container {
    padding-top: 0;
  }
  .footer img {
    width: 150px;
  }
  .nav_space {
    height: 2vh;
  }
}
@media (max-width: 450px) {
  .slideImage {
    width: 100%;
  }
}
