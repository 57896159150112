.imageTag_container {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
  background-color: #fff;
  width: fit-content;
  border-radius: 10px;
  margin: 0 10px;
}
.imageTag_container img {
  width: 80%;
}
@media (max-width: 1400px) {
  .imageTag_container img {
    width: 70%;
  }
}
@media (max-width: 650px) {
  .imageTag_container {
    padding: 15px;
  }
  .imageTag_container img {
    width: 30px;
  }
}
