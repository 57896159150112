.resume_container {
    position: relative;
    height: max-content;
}

body::-webkit-scrollbar {
    display: none;
}
.download {
    position: fixed;
    bottom: 5vh;
    left: 50%;
    transform: translateX(-50%);
    outline: none;
    border: 0;
    padding: 10px 30px;
    border-radius: 12px;
    background-color: #5637FA;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 1.2rem;
    cursor: pointer;
}
.download:hover {
    background-color: #4825f8;
}
.download:active {
    outline: 1px solid #000;
}
.close {
    position: absolute;
    right: 5vw;
    top: 5vh;
    width: 25px;
    cursor: pointer;
}
@media (max-width: 650px) {
    .close {
        top: 2.5vh;
        width: 15px;
    }
}