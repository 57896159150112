.container {
    height: 100vh;
    background-image: url(../../assets/images/404_illustration.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 115%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.header {
    font-family: "Poppins", sans-serif;
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    padding-top: 10vh;
    color: #fff;
}
.description {
    text-align: center;
    margin: 1.5vh 0;
    font-family: "Poppins", sans-serif;
    font-size: 1.4rem;   
    font-weight: 300;
    color: #fff;
}
.button {
    color: #fff;
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    font-size: 0.9rem;
    font-weight: 500;
    background-color: #5637FA;
    padding: 10px 30px;
    border-radius: 10px;
    box-sizing: border-box;
    margin-top: 2vh;
}
.button:hover {
    background-color: #4825f8;
}
.button:active {
    outline: 1px solid #000;
}