:root {
    --toastify-toast-width: 350px;
}

.Toastify__toast{
    background-color: #fff !important;
}

.Toastify__toast-body { 
    gap: 15px;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
}

.Toastify__close-button{
    align-self: center;
}

.Toastify__close-button > svg { 
    color: black;
    transform: translateX(-50%);
}
@media (min-width: 601px) {
    .Toastify__close-button > svg { 
        transform: translateX(-100%);
    }
}