@font-face {
  font-family: Garamond;
  src: url(./assets/fonts/GARA.TTF);
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body::-webkit-scrollbar {
  /* width: 0; */
}
.slick-slide {
  width: max-content;
  display: flex !important;
  justify-content: center;
  outline: none;
  cursor: pointer;
  z-index: 105;
}
.fp-watermark {
  display: none;
}
.section::-webkit-scrollbar {
  display: none;
}
#fp-nav ul li a span, .fp-slidesNav ul li a span{
  background: #5637FA !important;
}
/* #menu{
  width: 100%;
  position: fixed;
  display: block;
  z-index: 110;
} */