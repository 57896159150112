.navbar_wrapper {
  position: absolute;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 1.1rem;
  color: #25004d;
  background-color: transparent;
  top: 0;
  left: 0;
  transition: "top 0.6s";
  z-index: 1000;
  display: block;
}
.desktop_navlinks {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2%;
  height: 15vh;
}
.mobile_navlinks,
.expanded_menu {
  display: none;
}
.navlink,
.navlink_white {
  position: relative;
  height: 25px;
  padding: 0 20px;
  overflow: hidden;
  cursor: pointer;
  z-index: 115;
  text-decoration: none;
}
.navlink {
  color: #25004d;
}
.navlink_white {
  color: #fff;
}
.navlink a,
.navlink_white a {
  color: inherit;
  text-decoration: none;
}
.navlink:before,
.navlink_white:before {
  content: "";
  position: absolute;
  width: 0;
  height: 3px;
  bottom: 0;
  left: 0;
  right: 100%;
  border-radius: 100px;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
}
.navlink:before {
  background-color: #25004d;
}
.navlink_white:before {
  background-color: #fff;
}
.navlink:hover:before,
.navlink_white:hover:before {
  visibility: visible;
  width: 50%;
  left: 100%;
}
@media (max-width: 650px) {
  .close_icon {
    width: 50px;
  }
  .mobile_navlinks {
    display: flex;
    height: 15vh;
    justify-content: flex-end;
    align-items: center;
    padding: 0 10vw;
  }
  .desktop_navlinks {
    display: none;
  }
  .close_icon {
    width: 15px;
    margin: 10vh 0;
  }
  .expanded_menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    z-index: 1000000;
    height: 100%;
    width: 100%;
    background-color: #f2f3ff;
    transition: all 0.6s ease-out;
    font-size: 1.2rem;
    font-family: "Poppins", sans-serif;
  }
  .expanded_menu > .navlink,
  .expanded_menu > .navlink_white {
    margin: 2.5vh 0;
  }
}
